import React from "react";
import './modalEnd.css';
import elipse from './success.svg';

const Modal = ({active}) =>
{
    let textmodal2 = "Спасибо,"
    let textModal = "ваши отзывы помогают ";
    let textModal1 = "нам стать лучше для вас!"

    return (
        <div className="modal__content">
        <div className="modal__img">
        <img src={elipse}/>
        </div>
        <div className="modal__text">
            {textmodal2}
            <br/>
            {textModal}
            <br/>
            {textModal1}
        </div>
        </div>
            );

}

export default Modal;
