import React, { useState } from "react";
import './SendRevMap.css';
import map from './pin.svg'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import url from '../static/GlobalBag'
import token from '../static/Token'

const host = url();
const path = '/api/Reviews/ReviewSend';
const rToken = token();

function SendRevMap({urlMap, sourceUrlMap})
{
    const dispatch = useDispatch();
    const rev = useSelector(state => state.rev);
    const stars = useSelector(state => state.star);
    const dep = useSelector(state => state.depNum);

    let [response, setResponse] = useState("");
    
    let body = {
        "stars": stars,
        "textReview": rev,
        "restId": dep
    };


    async function GetRequest()
    {
        try
        {
            axios.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
            axios.defaults.headers.post['Authorization'] = rToken;
            let mainResponse = await axios.post(host + path, body);
            setResponse(mainResponse.data.urlMap);
            console.log(response);
        }       
        catch{}
    }

    let mpSource = "";

    if(sourceUrlMap != null)
    {
        if(sourceUrlMap == "Яндекс")
        {
            mpSource = "yandexmap";
        }
        else if(sourceUrlMap == "Google")
        {
            mpSource = "googlemap";
        }
        else if(sourceUrlMap == "2ГИС")
        {
            mpSource = "2gis";
        }
    }

    function click()
    {
        GetRequest();
        ym(95159177,'reachGoal', mpSource);
    }

    let text1 = "Вы поставили наивысшую оценку!"; 
    let text2 = " Расскажите о нас другим:";

    let textButton = "Оставить отзыв на Картах";
    return(
        <div>
            <div className="rectangle">
                <div className="text" >
                    {text1}
                    <br></br>
                    {text2}
                </div>
                <a href={urlMap}>
                
                <button className="button" onClick={click}>               
                    <div className="pos_text_img">
                        <div className="map_img_style">
                        <img src={map}></img>

                        </div>
                        <div className="textButton"> 
                            {textButton}
                        </div>
                    </div>
                </button>
                </a>
            </div>
        </div>);
}

export default SendRevMap;
