import React from "react";
import './MainText.css';

function MainText({depName})
{
    var dep = depName != null && depName != "" ? depName + '!': " "

    return(
        <div>
        <div className="textInfo">
            Рады снова видеть вас в ресторане «Кофемания» <br></br><br></br>       
            </div>
        <div className="textInfo">
            Будем признательны за отзыв о вашем посещении ресторана<br></br> 
        <div className="dep_name">{dep}</div>
        </div>
        </div>
    );
}

export default MainText;