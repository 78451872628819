import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/PFAgoraSlabPro-Black.ttf';
import './fonts/SuisseIntl-Black.otf';
import {createStore} from 'redux';
import {Provider,
        connect,
        useSelector,
        useDispatch} from 'react-redux';

const queryParams = new URLSearchParams(window.location.search)
let depNumber = 0;
let sign = "";

let path = window.location.pathname.split('/');
let filterData = path.filter((word) => word.length <= 4 && word != "");
let key = path[path.length - 1];

if(filterData.at(-1) != null)
{
  depNumber = filterData.at(-1);
}

const defaultState = {
  rev: "",
  star: 0,
  depNum: 0,
  sendRev: false,
}

const reducer = (state = defaultState, action) => 
{
    switch (action.type)
    {
        case "ADD_STAR":
          return {...state, star: action.payload}
        case "ADD_REV":
          return {...state, rev: action.payload}
        case "ADD_DEPNUMBER":
          return {...state, depNum: action.payload}
        case "GET_CASH":
          return {...state, cash: state.cash - action.payload}
        case "ADD_SEND_REV":
          return {...state, sendRev: action.payload}
        default:
          return state
    }
}

const store = createStore(reducer)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <React.StrictMode>
      <App depNumber={depNumber} sign = {key}/>
  </React.StrictMode>

  </Provider>
);
