// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.textInfo
{
    color: #393939;
    font-size: 16px;
    font-family: 'SuisseIntl';
    font-weight: 400;
    line-height: 21px;
    word-wrap: break-word;
    text-align: center;
    width: 335px;
}

.dep_name
{
    color: #393939;
    font-family: 'SuisseIntl';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  
}

`, "",{"version":3,"sources":["webpack://./src/components/MainText/MainText.css"],"names":[],"mappings":";AACA;;IAEI,cAAc;IACd,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;IAEI,cAAc;IACd,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;;AAErB","sourcesContent":["\r\n.textInfo\r\n{\r\n    color: #393939;\r\n    font-size: 16px;\r\n    font-family: 'SuisseIntl';\r\n    font-weight: 400;\r\n    line-height: 21px;\r\n    word-wrap: break-word;\r\n    text-align: center;\r\n    width: 335px;\r\n}\r\n\r\n.dep_name\r\n{\r\n    color: #393939;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 600;\r\n    line-height: 21px;\r\n  \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
