// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .mainstyle
 {
      padding: 20px 25px;
      display: flex;
 }
 
 .stars
 {
    display: flex;
    gap: 25px; 
    width: 355px;
    height: 50px;
    position: relative;
    left: 10%;
 }`, "",{"version":3,"sources":["webpack://./src/components/stars/star.css"],"names":[],"mappings":"CAAC;;MAEK,kBAAkB;MAClB,aAAa;CAClB;;CAEA;;IAEG,aAAa;IACb,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,SAAS;CACZ","sourcesContent":[" .mainstyle\r\n {\r\n      padding: 20px 25px;\r\n      display: flex;\r\n }\r\n \r\n .stars\r\n {\r\n    display: flex;\r\n    gap: 25px; \r\n    width: 355px;\r\n    height: 50px;\r\n    position: relative;\r\n    left: 10%;\r\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
