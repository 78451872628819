import React, { useState } from "react";
import './RevSend.css';
import Modal from "../modal/modalEnd";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import url from '../static/GlobalBag' 
import useWindowDimensions from'../static/useWindowDimensions';
import { DifferenceWidth } from "../static/cons-breacpoint";


const host = url();
const path = '/api/Reviews/ReviewSend';
const token = process.env.REACT_APP_TOKEN;

const Component = () => {
    const { height, width } = useWindowDimensions();
  
    return ({height, width});
  };


function RevSend(props)
{
    const [state, setStatusClic] = useState(false);
    const dispatch = useDispatch();
    const rev = useSelector(state => state.rev);
    const stars = useSelector(state => state.star);
    const dep = useSelector(state => state.depNum);

    const addCash = (cash) => 
    {
        dispatch({type: "ADD_SEND_REV", payload: true})
    }

    let [response, setResponse] = useState("");
    
    let body = {
        "stars": stars,
        "textReview": rev,
        "restId": dep,
        "sign": props.sign
    };

    async function GetRequest()
    {
        try
        {
            axios.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
            axios.defaults.headers.post['Authorization'] = token;
            let mainResponse = await axios.post(host + path, body);
            setResponse(mainResponse.data.urlMap);
            console.log(response);
        }       
        catch{}
    }


    function click()
    {
        setStatusClic(true);        
        addCash();
        GetRequest();
    }

    let widthButton = 360;
    let sizePage = Component();

    if(widthButton - sizePage.width > 0)
    {
        widthButton = sizePage.width - DifferenceWidth;
    }
    

    if(stars == 0)
    {
        return(
            <div>
                <button  className="button-style-not-pressd" 
                 style={{height: '50px', width: widthButton}}
                 disabled = {true}>Отправить</button>
            </div>);
    }

    if(state == true)
    {
        return(
             <div>             
                <Modal></Modal>
                <button  
                className="button-style-pressd" 
                style={{height: '50px', width: widthButton}}>
                    Отправить</button>
            </div>

             );
    }
    else if(state == false)
    {
            return(
             <div>
                <button className="button-style-pressd" 
                 style={{height: '50px', width: widthButton}}
                 onClick={click} >Отправить</button>
             </div>

             );
    }
}

export default RevSend;