// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.modal__content
{
    display: flex;
    width: 375px;
    height: 812px;
    flex-direction: column;
    justify-content: center;
    background: #FFF;
    align-items: center;
    gap: 16px;
    position: fixed;
    top: -10%;

}

.modal__text
{
    display: flex;
    color: var(--text_black, #212121);
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'SuisseIntl';
    font-size: 22px;
    font-style: normal;
    font-weight: 450;
    line-height: 30px; /* 143.75% */ 
}

.modal__img
{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 110px;
    height: 110px;
    position: relative;
    top: 0;
}

.model__vector
{
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 110px;
    height: 110px;
    position: relative;
    top: 0;
    right: 80%;
}`, "",{"version":3,"sources":["webpack://./src/components/modal/modalEnd.css"],"names":[],"mappings":";;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,SAAS;;AAEb;;AAEA;;IAEI,aAAa;IACb,iCAAiC;IACjC,kBAAkB;IAClB,6CAA6C;IAC7C,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,YAAY;AACnC;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,MAAM;AACV;;AAEA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,UAAU;AACd","sourcesContent":["\r\n\r\n.modal__content\r\n{\r\n    display: flex;\r\n    width: 375px;\r\n    height: 812px;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    background: #FFF;\r\n    align-items: center;\r\n    gap: 16px;\r\n    position: fixed;\r\n    top: -10%;\r\n\r\n}\r\n\r\n.modal__text\r\n{\r\n    display: flex;\r\n    color: var(--text_black, #212121);\r\n    text-align: center;\r\n    font-feature-settings: 'liga' off, 'clig' off;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 22px;\r\n    font-style: normal;\r\n    font-weight: 450;\r\n    line-height: 30px; /* 143.75% */ \r\n}\r\n\r\n.modal__img\r\n{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center; \r\n    width: 110px;\r\n    height: 110px;\r\n    position: relative;\r\n    top: 0;\r\n}\r\n\r\n.model__vector\r\n{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center; \r\n    width: 110px;\r\n    height: 110px;\r\n    position: relative;\r\n    top: 0;\r\n    right: 80%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
